.circleBtn{
    height:55px;
    width:55px;
    border-radius:  50%;
    margin: 5px;
    border: none;
    background-color: rgb(11, 252, 31);

}

.msginp{
    width:100%;
    height:55px;
    border-radius: 50px 50px 50px 50px;
    margin-top: 5px;
    margin-left: 5px;
    border: none;
    padding-left: 10px;

}

.cola{
    height:65px;
    width:65px;
    padding-left: 0px;
}
.msgalignend{
    display: flex;
    justify-content: flex-end;
    
}
.msgalignstart{
    display: flex;
    justify-content: flex-start;
    min-height: 5px;
    
}

.botmsg{
    background-color: rgb(205, 173, 241);
    padding-left: 15px;
    padding-right: 15px;
    height:auto;
    min-height: 45px;
    padding-top: 6px;
    padding-bottom: 4px;
    border-radius: 25px;
    max-width: 20rem;
}

.usermsg{
    background-color: rgb(235, 220, 252);
    padding-left: 15px;
    padding-right: 15px;
    height:auto;
    min-height: 45px;
    padding-top: 6px;
    padding-bottom: 4px;
    border-radius: 25px;
    max-width: 20rem;
    
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(140, 27, 185); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b909e6; 
  }

.botIcon{
    font-size: 45px;
    border : 1px solid black;
    border-radius: 50px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
}

.userIcon{
    font-size: 40px;
    border : 1px solid black;
    border-radius: 50px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;

}

.sendBtn{
    font-size:25px;
}